<template>
  <el-dialog
    :model-value="modelValue"
    title="申请记录"
    :width="980"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :append-to-body="true"
  >
    <el-table
      v-loading="ctrl.loading"
      class="border_table"
      :data="map.list"
      cell-class-name="cellName"
      header-cell-class-name="border_cell_name"
    >
      <el-table-column
        prop="nickname"
        label="教师名称"
      />
      <el-table-column
        prop="introduce"
        label="教师介绍"
        show-overflow-tooltip
        width="180"
      />
      <el-table-column
        prop="applyTime"
        label="申请时间"
        width="160"
      />
      <el-table-column
        prop="auditTime"
        label="审核时间"
        width="160"
      />
      <el-table-column label="审核状态">
        <template #default="scope">
          <div
            class="bg_dog"
            :class="{
              warning:scope.row.applyStatus === 1,
              success:scope.row.applyStatus === 2,
              error:scope.row.applyStatus === 3}"
          >
            <template v-if="scope.row.applyStatus === 1">
              待审核
            </template>
            <template v-else-if="scope.row.applyStatus === 2">
              已通过
            </template>
            <template v-else-if="scope.row.applyStatus === 3">
              <span>
                被驳回
              </span>
              <el-tooltip
                class="box-item"
                effect="dark"
                :content="scope.row.auditReason"
                placement="top"
              >
                <el-button type="text">
                  查看
                </el-button>
              </el-tooltip>
            </template>
            <template v-else-if="scope.row.applyStatus === 4">
              已撤回
            </template>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        fixed="right"
        align="center"
      >
        <template #default="scope">
          <el-button
            v-if="scope.row.applyStatus === 1"
            type="text"
            @click="handleRevokeApply(scope.row)"
          >
            撤销审核
          </el-button>
          <el-button
            v-else-if="scope.row.applyStatus !== 2"
            type="text"
            @click="handleEditApply(scope.row)"
          >
            修改申请
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div
      class="pagination"
    >
      <el-pagination
        v-model:currentPage="page.pageCurrent"
        v-model:pageSize="page.pageSize"
        background
        layout="total, prev, pager, next, jumper"
        :total="page.totalCount"
        @current-change="handleCurrentChange"
      />
    </div>
  </el-dialog>
</template>

<script setup>
import { teacherApplyRecordPage, teacherApplyRevoke } from '@/api/teacher.js'

const router = useRouter()
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: () => {
      return false
    }
  }
})
const emit = defineEmits(['close'])

watch(
  () => props.modelValue,
  (val) => {
    if (val) {
      viewExamRecord()
    }
  }
)

const map = reactive({
  list: []
})
const ctrl = reactive({
  loading: false
})
const page = reactive({
  totalCount: 0,
  pageCurrent: 1,
  pageSize: 10
})
const viewExamRecord = () => {
  const d = {
    pageCurrent: page.pageCurrent,
    pageSize: page.pageSize
  }
  ctrl.loading = true
  teacherApplyRecordPage(d)
    .then((res) => {
      page.pageCurrent = res.pageCurrent
      page.totalPage = res.totalPage
      page.totalCount = res.totalCount
      map.list = res.list || []
      ctrl.loading = false
    })
    .catch((e) => {
      handleClose()
      ctrl.loading = false
    })
}

const handleCurrentChange = (v) => {
  page.pageCurrent = v
  viewExamRecord()
}
const handleClose = () => {
  emit('close', false)
}

const handleRevokeApply = (row) => {
  teacherApplyRevoke({ id: row.id }).then(res => {
    if (res) {
      viewExamRecord()
    }
  })
}

const handleEditApply = (row) => {
  router.push({
    path: '/apply/teacher',
    query: {
      id: row.id
    }
  })
}
</script>
<style lang="scss" scoped>

.pagination {
  text-align: center;
}
.border_table{
  border: 1px solid #EBEEF5;
  border-bottom: 0;
}
.bg_dog{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  &.warning:before{
    color: rgba(253, 179, 68, 1);
  }
  &.error:before{
    color: #F23C3C;
  }
  &.success:before {
    color: #5daf34;
  }
  &:before{
    content: '●';
    color: #999;
    padding-right: 6px;
  }
}
</style>
