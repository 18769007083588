<template>
  <div class="search_box clearfix">
    <el-input
      ref="search"
      v-model="map.search"
      class="search_input"
      placeholder="请输入搜索内容"
      size="large"
      @blur="handleBlur"
      @focus="handleFocus"
      @keyup.enter="handleSearch"
    >
      <template #suffix>
        <el-button class="button_icon" @click="clickHistory(map.search)" >
          <el-icon class="el-input__icon">
            <search />
          </el-icon>
        </el-button>
      </template>
    </el-input>
    <div
      v-if="map.show && map.searchHistory && map.searchHistory.length > 0"
      class="history_box"
      @click="viewHistory"
    >
      <div class="row1">
        <span class="left">最近搜索</span>
        <div
          class="right"
          @click="clearHistory"
        >
          <el-icon>
            <delete />
          </el-icon>
          <span>清空</span>
        </div>
      </div>
      <template v-for="(item,index) in map.searchHistory">
        <div
          v-if="index < 5"
          :key="index"
          class="history_item"
          @click="clickHistory(item)"
        >
          {{ item }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import { getStore, setStore } from '@/utils/storage'
import { Delete, Search } from '@element-plus/icons-vue'

export default defineComponent({
  components: { Delete, Search },
  setup (props) {
    const map = reactive({
      search: '',
      show: false,
      searchHistory: []
    })
    const search = ref('')
    const route = useRoute()
    const router = useRouter()
    const clearHistory = () => {
      map.searchHistory = []
      setStore('searchHistory', map.searchHistory)
    }
    const clickHistory = (d) => {
      map.search = d
      if (map.search) {
        if (map.searchHistory.indexOf(map.search) > -1) {
          const index = map.searchHistory.indexOf(map.search)
          map.searchHistory.splice(index, 1)
          map.searchHistory.unshift(map.search)
        } else {
          map.searchHistory.unshift(map.search)
        }
        setStore('searchHistory', map.searchHistory)
      }
      handleSearch()
    }
    const handleSearch = () => {
      let tab = 5
      if (route.path.indexOf('course') > -1) {
        tab = 1
      }
      if (route.path.indexOf('grade') > -1) {
        tab = 2
      }
      if (route.path.indexOf('teacher') > -1) {
        tab = 3
      }
      if (route.path.indexOf('article') > -1) {
        tab = 4
      }
      if (route.path.indexOf('book') > -1) {
        tab = 5
      }
      if (route.path.indexOf('publisher') > -1) {
        tab = 6
      }
      router.push({
        name: 'search',
        query: { tab, t: map.search }
      })
      search.value.blur()
      map.show = false
      map.search = ''
    }
    const viewHistory = () => {
      map.isFocusHistory = true
    }
    const handleBlur = () => {
      setTimeout(() => {
        map.show = false
      }, 200)
    }
    const handleFocus = () => {
      if (route.path.indexOf('search') === -1) {
        map.show = true
        getHistory()
      }
    }
    const getHistory = () => {
      if (!getStore('searchHistory')) {
        setStore('searchHistory', [])
      } else {
        map.searchHistory = JSON.parse(getStore('searchHistory'))
      }
    }
    onMounted(() => {
      getHistory()
    })
    return {
      map,
      search,
      handleSearch,
      clickHistory,
      clearHistory,
      handleFocus,
      viewHistory,
      handleBlur
    }
  }
})
</script>

<style lang="scss" scoped>
.search_box {
  width: 220px;
  display: inline-block;
  vertical-align: top;
  height: 40px;
  margin: 0 16px 0 0;
  position: relative;
  form {
    width: 100%;
    border: none;
  }
}
.button_icon {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 20px;
  background: #2256f6;
  color: #fff;
}
.search_input {
  :deep(.el-input__wrapper) {
    padding-right: 0px;
    border-radius: 20px;
    background-color: rgba(247, 248, 250, 1);
    box-shadow: none;
  }
}

.search_btn {
  position: absolute;
  cursor: pointer;
  background: #fff;
  border: 0;
  left: 18px;
  top: 7px;
  background: none;
  font-size: #333;
  outline: none;

  .iconfont {
    font-size: 20px;
    color: #BECADB;
  }
}

.history_box {
  position: absolute;
  top: 54px;
  width: 100%;
  padding: 10px;
  background: #fff;
  border-radius: 0px 0px 10px 10px;
  z-index: 10;
  box-shadow: 0px 5px 15px rgba(54, 41, 183, 0.06);
  font-size: 14px;

  .row1 {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;

    .left {
      font-weight: bold;
    }

    .right {
      color: #BECADB;
      display: flex;
      column-gap: 4px;
      align-items: center;
      cursor: pointer;
    }
  }

  .history_item {
    padding: 5px 0px;
    cursor: pointer;

    &:hover {
      color: #2256F6;
    }
  }
}
</style>
